import styled from '@emotion/styled';
import theme from '@24hr/resursbank-theme/ya-bank';
import Heading from '../../components/heading';

const nthChild = val => `${val}n`;
export const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    .clickable-infobox {
        background-color: #FFFFFF;
        box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.17);
        color: inherit;
        min-height: 276px;
        margin-bottom: ${theme.spacing.base};
        padding: ${theme.spacing.xlarge};
        margin-right: ${theme.spacing.base};
        width: calc(${p => 100 / p.numColumns}% - ${theme.spacing.base});
        &:nth-child(${p => nthChild(p.numColumns)}) {
            margin-right: 0;
        }
        ${theme.mediaQueries.mobileMax} {
            width: 100%;
            height: auto;
            display: block;
            box-shadow: none;
            border-bottom: 1px solid ${theme.colors.superLightGrey};
            padding: ${theme.spacing.xlarge} ${theme.spacing.large};
            margin-bottom: 1px;
            margin-right: 0;
            .link--default {
                display: block;
                margin-top: ${theme.spacing.large};
            }
        }
        transition: background-color ease 0.3s;
    }

    ${theme.mediaQueries.tabletMin} {
        .clickable-infobox--link:hover {
            background-color: ${theme.colors.superLightGrey};
        }
    }
`;

export const StyledBox = styled.div``;

export const StyledHeader = styled(Heading)`
    margin-bottom: ${theme.spacing.base};
    ${theme.mediaQueries.mobileMax} {
        margin-bottom: ${theme.spacing.large};
    }
`;
