import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import theme from '@24hr/resursbank-theme/ya-bank';
import cx from 'classnames';

import Text from '../../components/text';
import Link from '../../components/link';
import Button from '../../components/button-component';

import { StyledContainer, StyledBox, StyledHeader } from './style';

const mapStateToProps = state => ({
    viewport: state.viewport,
});

const buttonStyles = {
    fullWidth: true,
    background: 'white',
    textAlign: 'center',
    color: 'black',
    variant: 'filled',
    border: {
        shorthand: 'border',
        color: 'lightGrey',
        width: 1,
    },
};

const ClickableInfoBoxes = ({ viewport, infoBoxes, numColumns }, { localize }) => {
    const isMobile = viewport.width <= theme.breakpoints.tablet;
    return (
        <StyledContainer numColumns={numColumns}>
            {infoBoxes.map((box, index) => {
                const url = box.linkTo?.url;
                const target = box.linkTo?.target;
                const classNames = cx({
                    'clickable-infobox': true,
                    'clickable-infobox--link': !!url,
                });
                const args = {
                    key: `clickable-infobox-${index}`,
                    className: classNames,
                    ...(!isMobile && url && { to: url }),
                    ...(!isMobile && target && { target: target }),
                    numColumns,
                };
                const InfoBox = url && !isMobile ? Link : StyledBox;
                return (
                    <InfoBox {...args}>
                        <StyledHeader tag="h3">{box.title}</StyledHeader>
                        <Text size={isMobile ? 'small' : 'normal'}>
                            {box.subtext}
                        </Text>
                        {isMobile && url && (
                            <Button
                                {...buttonStyles}
                                url={url}
                                target={target || null}
                            >
                                {localize('read-more')}
                            </Button>
                        )}
                    </InfoBox>
                );
            })}
        </StyledContainer>
    );
};

ClickableInfoBoxes.parseProps = atts => {
    return {
        infoBoxes: atts.boxes || [],
        numColumns: atts.numColumns || 2,
    };
};

ClickableInfoBoxes.defaultProps = {
    infoBoxes: [],
    numColumns: 2,
};

ClickableInfoBoxes.propTypes = {
    infoBoxes: PropTypes.array,
    viewport: PropTypes.object,
    numColumns: PropTypes.number,
};

ClickableInfoBoxes.contextTypes = {
    localize: PropTypes.func,
};

export default connect(mapStateToProps)(ClickableInfoBoxes);
